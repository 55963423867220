import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from '@makeship/core';
import styled from 'styled-components';
import posthog from 'posthog-js';
import Container from '../../../Container';
import { H2, H4Styles, P1 } from '../../../Typography';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { useStore } from '../../../../store';

const CommunitySectionBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.oceanBlue};
`;

const CommunitySection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: bottom center;
  padding: 64px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 500px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 100%;
    flex-direction: column;
    width: 100%;
    background-position: 85%;
    padding: 16px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right 30% top calc(8vh - 16px);
  }
`;

const CommunityBody = styled.div`
  width: 50%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 32px;
    width: 50%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

const CommunityTitle = styled(H2)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryDark};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${H4Styles}
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const CommunityP1 = styled(P1)`
  padding: 16px 0 32px 0;
`;

const CommunityImageWrapper = styled.div`
  position: relative;
  width: 40%;
  height: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    height: 326px;
    margin-top: 16px;
  }
`;

const Community: React.FC = () => {
  const { state } = useStore();
  return (
    <CommunitySectionBackground>
      <Container>
        <CommunitySection>
          <CommunityBody>
            <CommunityTitle>Join the Community</CommunityTitle>
            <CommunityP1>
              Designed by Creators. Fueled by Fans! Don&apos;t miss out on your opportunity to discover incredibly
              talented Creators and their passionate fans!
            </CommunityP1>
            <Link href="/community" passHref>
              <Button.Primary
                onClick={() =>
                  posthog.capture('home_joinCommunityCTA_click', {
                    is_logged_in: isUserLoggedIn(state.user),
                    link_href: '/community',
                  })
                }
              >
                Learn More
              </Button.Primary>
            </Link>
          </CommunityBody>
          <CommunityImageWrapper>
            <Image
              src="/assets/home-page/hear-it-from-the-community.png"
              layout="fill"
              objectFit="contain"
              quality={90}
            />
          </CommunityImageWrapper>
        </CommunitySection>
      </Container>
    </CommunitySectionBackground>
  );
};

export default Community;
