import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { Button } from '@makeship/core';
import posthog from 'posthog-js';
import Container from '../../../Container';
import { H2, H4Styles, P1 } from '../../../Typography';
import { useStore } from '../../../../store';
import { isUserLoggedIn } from '../../../../utils/analytics';

const AtMakeshipBackground = styled.div`
  background: linear-gradient(
    180deg,
    rgba(83, 73, 237, 0) 15.11%,
    rgba(83, 73, 237, 0.3) 64.59%,
    rgba(83, 73, 237, 0.77) 100%
  );
`;

const AtMakeshipSectionWrapper = styled(Container)`
  margin-bottom: -48px;
`;

const AtMakeshipSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 550px;
  padding: 0 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: auto;
    padding: 0 16px;
    flex-direction: column;
  }
`;

const AtMakeshipImageWrapper = styled.div`
  overflow: hidden;
  pointer-events: none;
  position: relative;
  width: 392px;
  height: 392px;
  margin-top: auto;
  object-fit: cover;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 258px;
    height: 258px;
    margin-top: -48px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 90vw;
    height: 90vw;
    margin-top: 24px;
  }
`;

const AtMakeshipBody = styled.div`
  width: 40%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 32px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

const AtMakeshipTitle = styled(H2)`
  color: ${({ theme }) => theme.colors.primaryDark};
  text-transform: uppercase;
  font-size: 38px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${H4Styles}
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const AtMakeshipP1 = styled(P1)`
  color: ${({ theme }) => theme.colors.black};
  padding: 16px 0;
  width: 90%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

const AtMakeshipButton = styled(Button.Quaternary)`
  margin-top: 16px;
`;

const AtMakeship: React.FC = () => {
  const { state } = useStore();
  return (
    <AtMakeshipBackground>
      <AtMakeshipSectionWrapper>
        <AtMakeshipSection>
          <AtMakeshipBody>
            <AtMakeshipTitle>Ready to Launch a campaign?</AtMakeshipTitle>
            <AtMakeshipP1>
              Makeship will work with you to design, sell, promote, and manufacture a custom product created by you, for
              your fans! And the best part? It&apos;s risk free with no investment needed!!
            </AtMakeshipP1>
            <Link href="/launch-campaign" passHref>
              <AtMakeshipButton
                onClick={() =>
                  posthog.capture('home_launchCampaignCTA_click', {
                    is_logged_in: isUserLoggedIn(state.user),
                    link_href: '/launch-campaign',
                  })
                }
              >
                Learn More
              </AtMakeshipButton>
            </Link>
          </AtMakeshipBody>
          <AtMakeshipImageWrapper>
            <Image src="/assets/home-page/index-rocket.png" layout="fill" objectFit="contain" quality={90} />
          </AtMakeshipImageWrapper>
        </AtMakeshipSection>
      </AtMakeshipSectionWrapper>
    </AtMakeshipBackground>
  );
};

export default AtMakeship;
