import React from 'react';
import Image from 'next/image';
import posthog from 'posthog-js';
import styled from 'styled-components';
import StyledButton from '@makeship/core/lib/components/Button';
import { P1Styles, P1, S1 } from '../../../Typography';
import Container from '../../../Container';
import config from '../../../../../config.json';

const { routes } = config;

const MastheadWrapper = styled.div`
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const MastheadDesktopBackgroundWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: block;
  }
`;

const MastheadTabletBackgroundWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.largeTablet - 1}px) {
    display: block;
  }
`;

const MastheadMobileBackgroundWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet - 1}px) {
    display: block;
  }
`;

const MastheadContainer = styled(Container)`
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 16px;
  justify-content: space-between;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    width: 80%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.maxContent}px) {
    width: 70%;
  }
`;

const LargeDesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    display: block;
    width: 548px;
    height: 548px;
  }
`;

const DesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.largeDesktop - 1}px) {
    display: block;
    width: 500px;
    height: 500px;
  }
`;

const SmallDesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.desktop - 1}px) {
    display: block;
    width: 424px;
    height: 424px;
  }
`;

const MobilePlushieImageWrapper = styled.div`
  width: 282px;
  height: 282px;
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet - 1}px) {
    display: block;
  }
`;

const InfoWrapper = styled.div`
  background: linear-gradient(180deg, #31068a 0%, #140339 100%);
  display: flex;
  padding: 24px 16px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  max-width: 468px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 400px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 480px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    max-width: 420px;
    padding: 42px 32px;
    justify-content: space-between;
    height: 410px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 398px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    max-width: 468px;
  }
`;

const InfoLogoWrapper = styled.div`
  display: block;
  max-width: 240px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    max-width: 332px;
  }
`;

const InfoHeaderText = styled(S1)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
`;

const InfoText = styled(P1)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    ${P1Styles}
    color: ${({ theme }) => theme.colors.neutral1};
  }
`;

const InfoStyledButton = styled(StyledButton.Tertiary)`
  color: ${({ theme }) => theme.colors.neutral7};
  border: transparent;
  padding: 14px 24px;
  width: 280px;
  &:active {
    border: transparent;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 336px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 280px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const InfoContentWrapper = styled.div``;

const GITDMasthead: React.FC = () => (
  <MastheadWrapper>
    <MastheadDesktopBackgroundWrapper>
      <Image src="/assets/gitd2024/GITD_24_Masthead-Desktop.png" layout="fill" objectFit="cover" quality={90} />
    </MastheadDesktopBackgroundWrapper>
    <MastheadTabletBackgroundWrapper>
      <Image src="/assets/gitd2024/GITD_24_Masthead-Tablet.png" layout="fill" objectFit="cover" quality={90} />
    </MastheadTabletBackgroundWrapper>
    <MastheadMobileBackgroundWrapper>
      <Image src="/assets/gitd2024/GITD_24_Masthead-Mobile.png" layout="fill" objectFit="cover" quality={90} />
    </MastheadMobileBackgroundWrapper>

    <MastheadContainer>
      <InfoWrapper>
        <InfoLogoWrapper>
          <Image src="/assets/gitd2024/GITD-2024_Logo.png" width="332" height="89" quality={90} />
        </InfoLogoWrapper>
        <InfoContentWrapper>
          <InfoHeaderText>Ready, set, glow! 🌟</InfoHeaderText>
          <InfoText>
            Check out your favorite creators&apos; newest glow-in-the-dark products with limited time discounts!
          </InfoText>
        </InfoContentWrapper>

        <ButtonContainer>
          <a href={`${routes.shopGITD}`}>
            <InfoStyledButton onClick={() => posthog.capture('homepage_GITD2024-masthead_cta_click')}>
              Shop GITD Campaigns
            </InfoStyledButton>
          </a>
        </ButtonContainer>
      </InfoWrapper>
      <div>
        <LargeDesktopPlushieImageWrapper>
          <Image src="/assets/gitd2024/GITD_24_Masthead-ProductImage 1.png" width="548" height="548" quality={90} />
        </LargeDesktopPlushieImageWrapper>
        <DesktopPlushieImageWrapper>
          <Image src="/assets/gitd2024/GITD_24_Masthead-ProductImage 1.png" width="500" height="500" quality={90} />
        </DesktopPlushieImageWrapper>
        <SmallDesktopPlushieImageWrapper>
          <Image src="/assets/gitd2024/GITD_24_Masthead-ProductImage 1.png" width="424" height="424" quality={90} />
        </SmallDesktopPlushieImageWrapper>
        <MobilePlushieImageWrapper>
          <Image src="/assets/gitd2024/GITD_24_Masthead-ProductImage 1.png" width="282" height="282" quality={90} />
        </MobilePlushieImageWrapper>
      </div>
    </MastheadContainer>
  </MastheadWrapper>
);
export default GITDMasthead;
