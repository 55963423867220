import React from 'react';
import styled from 'styled-components';
import { getCollectionProducts } from '../../../../utils/collections';
import Carousel from '../../../Carousel';
import Container from '../../../Container';
import ProductCard from '../../../ProductCard';
import config from '../../../../../config.json';

const { collections } = config;

const ComingSoonBackground = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(83, 73, 237, 0.31) 28.46%, #5349ed 50.75%);
`;

const ComingSoonContainer = styled(Container)`
  padding: 8px 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 1px 16px 72px 16px;
  }
`;

const CarouselWrapper = styled.div`
  margin: 120px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 52px 0 16px 0;
  }
`;

type ComingSoonProps = {
  comingSoon: Shopify.ProductEdge[] | null;
};

const ComingSoon = ({ comingSoon }: ComingSoonProps) => (
  <ComingSoonBackground>
    {comingSoon && comingSoon.length > 0 && (
      <ComingSoonContainer>
        <CarouselWrapper>
          <Carousel
            title="Coming Soon"
            link={`/shop/${collections.comingSoon}`}
            isInCollection
            isOnMainPage
            collectionForTracking={comingSoon}
            carouselLocation="home"
          >
            {getCollectionProducts(comingSoon).map(({ node }) => (
              <ProductCard key={node.id} product={node} isOnMainPage titleForTracking="Coming Soon" />
            ))}
          </Carousel>
        </CarouselWrapper>
      </ComingSoonContainer>
    )}
  </ComingSoonBackground>
);

export default ComingSoon;
